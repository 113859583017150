<template lang="">
    <div class="w-100 h-100">
        <div class="application-confirmation">
            <div>
                <img :src="applicationImage" alt="applicationImage"/>
            </div>
            <div class="application-confirmation-title">
                {{ titleComputed }}
            </div>

             <div v-if="failed" class="my-4">
                 <div class="w-100 text-center mt-5">
                    <i class="icon-5x far fa-times-circle text-danger"></i>
                    <p class="font-size-sm mt-10">
                        {{ $t('APPLICATION.PAYMENT_REDIRECT_TEXT') }}
                    </p>
                </div>
            </div>
            <div v-else>
                <div class="w-100 text-center mt-5">
                    <i class="icon-5x far fa-check-circle text-success"></i>
                </div>
                <div class="alert alert-custom alert-light-warning fade show my-10" role="alert">
                    <div class="alert-icon"><i class="fas fa-exclamation-triangle"></i></div>
                    <div class="alert-text text-center">
                        <span v-if="!isRegistryApplication" class="font-size-sm">
                            {{ $t('APPLICATION.PAYMENT_NOTIFICATION_TEXT') }}
                        </span>
                        <p class="font-size-sm mt-2 mb-0">
                            {{ $t('APPLICATION.PAYMENT_REDIRECT_TEXT') }}
                        </p>
                    </div>
                </div>
            </div>
            <div>
                <st-button
                    variant="primary"
                    :disabled="!canRedirect"
                    :callback="redirectTo"
                    >{{ $t('GENERAL.BUTTON.CONTINUE') }}
                </st-button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { ApplicationsPermissions } from "@/modules/applications/applications-permissions";

export default {
    name: "ApplicationPaymentNotify",
    props: {
        id: {
            type: String,
        },
    },
    data() {
        return {
            applicationImage: `${process.env.BASE_URL}media/st-application-registred-trees.png`,
            failed: false,
            canRedirect: false,
            isRegistryApplication: false
        };
    },
    computed: {
        ...mapGetters({
            paymentStatus: 'applications/form/paymentStatus',
            record: "applications/form/record",
            currentUser: 'auth/currentUser',
        }),
        titleComputed() {
            if (this.failed) {
                return this.$t('APPLICATION.PAYMENT_FAILED_TITLE');
            } else {
                return this.$t('APPLICATION.PAYMENT_SUCCESS_TITLE');
            }
        },
        hasPermissionToOrganisationsApplication() {
            return new ApplicationsPermissions(this.currentUser).readStaff;
        },
        hasPermissionToStaffOwnApplications() {
            return new ApplicationsPermissions(this.currentUser).readOwnStaff;
        },
        hasPermissionToReadStaff() {
            return this.hasPermissionToOrganisationsApplication || this.hasPermissionToStaffOwnApplications;
        },        
    },
    methods: {
        ...mapActions({
            checkPaymentStatus: 'applications/form/checkPaymentStatus',
            checkRegistryPaymentStatus: 'applications/form/checkRegistryPaymentStatus',
            doSendRegistry: 'applications/form/registrySend',
            doFind: "applications/form/find",
        }),
        redirectTo() {
            if (this.isRegistryApplication) {
                this.$router.push({
                    name: 'applicationView',
                    params: {
                        id: this.id,
                        tab: 'details'
                    },
                });
                return;                
            }
            this.$router.replace({ name: 'applicationEdit', params: { id: this.id }, query: { state: 'redirect' } });
        },
        timeoutRedirect() {
            this.canRedirect = true;
            this.timeoutId = setTimeout(() => {
                this.redirectTo();
            }, 3000);
        }
    },
    watch: {
        paymentStatus: function(status) {
            if (status !== 'created') {
                clearInterval(this.polling);
                this.timeoutRedirect();
                if (status === 'successful' && this.isRegistryApplication) {
                    this.doSendRegistry({
                        id: this.id,
                        values: null,
                    });
                }                 
            }
        },
    },
    async created() {
        if (this.id && !this.record) {
            try {
                await this.doFind({
                    id: this.id,
                    loadStaff: this.hasPermissionToReadStaff,
                });
                this.recordLoaded = true;
                this.isRegistryApplication = this.record?.is_registry_application;
            } catch {
                //return await this.$router.push({ name: previousRoute.name ? previousRoute : 'applications' });
            }

        }         
        this.failed = this.$route?.query?.failed === 'true';
        if (this.failed) {
            this.timeoutRedirect();
        } else {
            this.polling = setInterval(() => {
                if (this.isRegistryApplication) {
                    this.checkRegistryPaymentStatus(this.id);
                } else {
                    this.checkPaymentStatus(this.id);
                }
            }, 1000);
        }        
    },
    beforeDestroy() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        this.timeoutId = null;
        if (this.polling) {
            clearTimeout(this.polling);
        }
        this.polling = null;
    },
};
</script>
<style lang="">
</style>
